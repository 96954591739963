/* Put your global styles here */
@import 'normalize';
@import 'boilerplate';
@import 'variables';
@import 'typo';
@import 'layout';
@import 'nav';

body {  
  background-color: $bgcolor;
  color: $maincolor;
}

a {
  color: $maincolor;
}

ul,
li {
  list-style: none;
  margin:0;
  padding:0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}
 
.active, a:hover{
  color: $highlightcolor;
}

