/* Put your layout here */

body,html {
  height: 100%;
}

.side {
  width: 50%;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction:column;
  justify-content:center;
  text-align: center;
}

.header {
  position: fixed;
  top:15px;
  width: 50%;
}

.logowrapper {

}

.main {
  width: calc(50% - 20px);
  float: right;
  margin: 0 10px;
}

nav {
  ul {
    
  }
  li {
    display: inline-block;
    margin: 0 10px;

    &:hover {
      text-decoration: underline;
    }
  }
}

form {
  margin: 40px 0;
}

label {
  display: block;
}
.row {
  margin: 20px 0;
}
input[type="text"],input[type="email"], textarea {
  width: calc(100% - 12px);
  padding: 10px 5px;
  background:$bgcolor;
  border:1px solid $maincolor;
}

input[type="submit"], .ul {
  background:$maincolor;
  padding:10px 20px;
  margin-top: 10px;
  color:white;
  border:1px solid $maincolor;
  text-decoration: none;
  &:hover {
    background:white;
    color:$maincolor;
  }
}
input[type="checkbox"]{
// vertical-align: middle;
}

input,textarea {
  border-radius: 0;
}

.cbl span {
  // vertical-align: middle;
  margin-left: 3px;
}

.ul {
  display: inline-block;
  margin-top: 10px;
}

h1 {
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
}

.home h1 {
  text-align: left;
}
p.img {
  margin-top: 30px;
  margin-bottom: 0;
}
.copyright {
  text-align: right;  
  margin: 0 5px 0 0;
  padding: 0;
  // background:pink;
}

@media only screen and (max-width: 45em) {
  .side, .main {

    width: calc(100% - 20px);
    position: relative;
    margin: 0 10px;

    .header {
      position: absolute;
      width: 100%;
    }
    
    .logo {
      line-height: 3.5rem;
      margin-bottom: -5px;
    }
    .logowrapper {
      margin-top: 50px;
    }

    h1 {
      text-align: left;
      margin-top: 30px;
      margin-bottom: 0;
     
      //margin-left: 20px;
      font-size: 1.25rem;
    }
  }
  /* Style adjustments for viewports that meet the condition */
  
}