
@import url('https://fonts.googleapis.com/css?family=Arapey');

html {
  -webkit-font-smoothing: antialiased;
}

body {  
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: normal;  
}

.logo {
  font-size: 4rem;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-family: 'Arapey', serif;
  a {
    text-decoration: none;
  }
}

.baseline {
  font-size: 0.75rem;
  text-transform: uppercase;
}
.copyright {
  font-size: 0.8rem;
}

nav a {
  text-decoration: none;
  text-transform: uppercase;
}

h1 {
  margin-top: 15px;
  line-height: 2rem;
}

